import React from 'react'
import Pagewrapper from '../components/PageWrapper'
import SEO from '../components/seo'
import Container from '../components/Landing/Container'

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<
                React.HTMLAttributes<HTMLElement>,
                HTMLElement
            >
        }
    }
}

export default function () {
    const apiKey = process.env.GATSBY_STRIPE_PUBLIC_KEY || ''
    const pricingTableId = process.env.GATSBY_STRIPE_PRICING_TABLE_ID || ''
    return (
        <Container pathName="subcription-selector">
            <SEO title="Subcription-selector" />
            <stripe-pricing-table
                pricing-table-id={pricingTableId}
                publishable-key={apiKey}
            ></stripe-pricing-table>
        </Container>
    )
}
